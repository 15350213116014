import { theme } from "../theme/theme";
import { ThemeProvider } from "theme-ui";
import "./index.css";
import * as React from "react";
import "../App.css";
import { LandingPage } from "./LandingPage/LandingPage";
import { graphql, PageProps } from "gatsby";
import { ILandingPage } from "../types";
import { Helmet } from "react-helmet";

function App({ data }: any): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>RealCore Group</title>
        </Helmet>
        <LandingPage {...(data as ILandingPage)}></LandingPage>
      </div>
    </ThemeProvider>
  );
}

export const query = graphql`
  query MyQuery {
    contentfulLandingPage {
      module {
        ... on ContentfulLogoTitleModul {
          id
          title
          internal {
            type
          }
        }
        ... on ContentfulOurPassion {
          id
          text1
          text2
          internal {
            type
          }
        }
        ... on ContentfulOurPhilosophy {
          id
          motto
          text
          image {
            file {
              url
            }
            description
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulOurValues {
          id
          internal {
            type
          }
          title
          appreciationText {
            appreciationText
          }
          appreciationTitle1
          appreciationTitle2
          innovationText {
            innovationText
          }
          innovationTitle1
          innovationTitle2
          motivatedText {
            motivatedText
          }
          motivatedTitle1
          motivatedTitle2
          sustainabilityText {
            sustainabilityText
          }
          sustainabilityTitle1
          sustainabilityTitle2
          trustText {
            trustText
          }
          trustTitle1
          trustTitle2
        }
        ... on ContentfulOurWork {
          id
          benefitsPoints
          benefitsTitle
          benefitsTitleFirst
          servicePoints
          serviceTitle
          serviceTitleFirst
          workBalancePoints
          workBalanceTitle
          workBalanceTitleFirst
          internal {
            type
          }
        }
        ... on ContentfulImageGallery {
          id
          internal {
            type
          }
          galleryCards {
            name
            subTitle
            color
            customColor
            subTitleColor
            subTitleCustomColor
            additionalStyles
            image {
              description
              title
              file {
                url
              }
            }
          }
          title
        }
        ... on ContentfulTechnologyGallery {
          id
          title
          technologyText {
            technologyText
          }
          technologyCards {
            title
            description {
              description
            }
            image {
              title
              description
              file {
                url
              }
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulContactInformation {
          id
          title
          companyName
          addressLine1
          addressLine2
          email
          image {
            file {
              url
            }
            description
            title
          }
          copyright
          internal {
            type
          }
        }
        ... on ContentfulLocations {
          id
          title
          featuredLocation
          featuredCompany
          locationOne
          locationTwo
          locationThree
          locationFour
          locationFive
          locationSix
          locationSeven
          locationEight
          locationNine
          internal {
            type
          }
        }
        ... on ContentfulContactLinks {
          email
          linkedIn
          daVinci
          facebook
          internal {
            type
          }
        }
      }
    }
  }
`;

export default App;
